import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mediaQueries } from '../../theme'

const headingStyles = ({ theme }) => css`
  font-family: ${theme.fonts.heading};
  font-weight: ${theme.fontWeights.heading};
  line-height: ${theme.lineHeights.heading};
  margin: ${theme.space[0]} 0 ${theme.space[2]};
`

const listStyles = ({ theme }) => css`
  margin: ${theme.space[6]} 0 ${theme.space[8]};
  padding-left: ${theme.space[14]};
  font-family: Merriweather, serif;
`

const A = styled.a`
  color: ${p => p.theme.colors.black[0]};
  text-decoration: underline;

  :hover {
    color: ${p => p.theme.colors.white};
    text-decoration: none;
    background-color: ${p => p.theme.colors.black[0]};
  }
`

const P = styled.p`
  margin: ${p => `${p.theme.space[0]} 0 ${p.theme.space[6]}`};
  font-size: ${p => p.theme.fontSizes[3]};
  font-weight: 400;
  line-height: 2;
  font-family: Merriweather, serif;

  .post-gif,
  .gatsby-resp-image-wrapper {
    box-shadow: ${p => p.theme.shadows.overlay};
    margin: ${p => `${p.theme.space[10]} 0 ${p.theme.space[12]} !important`};

    ${mediaQueries.sm} {
      margin: ${p => `${p.theme.space[10]} 0 ${p.theme.space[12]} !important`};
    }
  }

  .post-zoom-image .gatsby-resp-image-wrapper:before {
    content: 'Click to see full image';
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    font-size: ${p => p.theme.fontSizes[2]};
    color: ${p => p.theme.colors.black[0]};
    background: ${p => p.theme.colors.gray[2]};
    z-index: 1;
  }

  .post-zoom-image:hover .gatsby-resp-image-wrapper:before {
    color: ${p => p.theme.colors.white};
    background: ${p => p.theme.colors.black[0]};
  }
`

const H1 = styled.h1`
  ${headingStyles}
  font-size: ${p => p.theme.fontSizes[8]};
  line-height: ${p => p.theme.lineHeights.solid};

  ${mediaQueries.lg} {
    font-size: ${p => p.theme.fontSizes[17]};
    line-height: ${p => p.theme.lineHeights.heading};
  }
`

const H2 = styled.h2`
  ${headingStyles}
  font-size: ${p => p.theme.fontSizes[7]};
  margin: ${p => `${p.theme.space[21]} 0 ${p.theme.space[10]}`};

  ${mediaQueries.lg} {
    font-size: ${p => p.theme.fontSizes[13]};
  }
`

const H3 = styled.h3`
  ${headingStyles}
  font-size: ${p => p.theme.fontSizes[6]};
  margin: ${p => `${p.theme.space[21]} 0 ${p.theme.space[6]}`};

  &.anchor {
    padding-top: ${p => p.theme.space[21]};
    margin-top: 0;
  }

  ${mediaQueries.lg} {
    font-size: ${p => p.theme.fontSizes[7]};
  }
`

const H4 = styled.h4`
  ${headingStyles}
  margin: ${p => `${p.theme.space[21]} 0 ${p.theme.space[10]}`};
  font-size: ${p => p.theme.fontSizes[5]};
`

const H5 = styled.h5`
  ${headingStyles}
  margin-bottom: ${p => p.theme.space[5]};
  font-size: ${p => p.theme.fontSizes[3]};
  line-height: 1.5;
`

const H6 = styled.h6`
  ${headingStyles}
  margin: ${p => `${p.theme.space[8]} 0 ${p.theme.space[5]}`};
  font-size: ${p => p.theme.fontSizes[2]};
`

const BlockQuote = styled.blockquote`
  font-size: ${p => p.theme.fontSizes[4]};
`

const Ol = styled.ol`
  ${listStyles}
`

const Ul = styled.ul`
  ${listStyles}
  list-style: square;
`

const Li = styled.li`
  padding: ${p => `${p.theme.space[3]} 0`};
  font-size: ${p => p.theme.fontSizes[3]};
  font-weight: 400;

  &:first-of-type {
    padding-top: 0;
  }
`

const Pre = styled.pre`
  font-family: ${p => p.theme.fonts.monospace};
  overflow-x: auto;

  code {
    color: inherit;
  }
`

const Code = styled.code`
  font-family: ${p => p.theme.fonts.monospace};
  font-size: inherit;
`

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
`

const Th = styled.th`
  text-align: left;
  border-bottom-style: solid;
`

const Td = styled.td`
  text-align: left;
  border-bottom-style: solid;
`

const Img = styled.img`
  max-width: 100%;
`

const B = styled.b`
  font-weight: ${p => p.theme.fontWeights.heavy};
`

const Strong = styled.strong`
  font-weight: ${p => p.theme.fontWeights.heavy};
`

export { H1, H2, H3, H4, H5, H6 }

const components = {
  a: A,
  p: P,
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  blockquote: BlockQuote,
  ol: Ol,
  ul: Ul,
  li: Li,
  pre: Pre,
  code: Code,
  table: Table,
  th: Th,
  td: Td,
  img: Img,
  b: B,
  strong: Strong,
}

export default components
