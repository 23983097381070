import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mediaQueries } from '../../theme'
import { H1, H2, H3, H4, H5, H6 } from './mdx-components'

export const Heading = styled(H1)`
  margin: 0 0 ${p => p.theme.space[16]};
  ${mediaQueries.lg} {
    margin: 0 0 ${p => p.theme.space[21]};
  }
`
export const Lead = styled.p`
  margin-bottom: ${p => p.theme.space[16]};
  ${mediaQueries.lg} {
    margin-bottom: ${p => p.theme.space[21]};
  }
`
export const Article = styled.article`
  & + & {
    margin-top: ${p => p.theme.space[18]};
    ${mediaQueries.lg} {
      margin-top: ${p => p.theme.space[21]};
    }
  }

  p + p,
  p + ul,
  ol + p,
  ul + p {
    margin-top: ${p => p.theme.space[6]};
  }

  & > ul {
    margin-left: ${p => p.theme.space[5]};
  }
`
export const ArticleHeading = styled(H4)`
  margin: ${p => `0 0 ${p.theme.space[6]}`};
`
export const P = styled.p`
  margin: 0;

  & > ul,
  & > ol {
    margin-left: ${p => p.theme.space[5]};
  }
`

const listStyles = css`
  margin: 1.5rem 0;
  padding: 0;

  li + li {
    margin-top: 1.5rem;
  }

  & ol,
  & ul {
    margin-left: 1.25rem;
  }
`

export const Ul = styled.ul`
  margin-left: ${p => p.theme.space[5]};
  ${listStyles}
`
export const Ol = styled.ol`
  counter-reset: item;
  list-style: inside;
  ${listStyles}

  & > li {
    display: block;
  }

  & > li::before {
    display: inline-block;
    counter-increment: item;
    width: 1.75em;

    ${p =>
      p.counter === 'letter' &&
      `
      content: '(' counter(item, lower-alpha) ')' ;
    `}

    ${p =>
      p.counter === 'number' &&
      `
      content: counter(item,decimal) '.';
    `}

    ${p =>
      p.counter === 'roman' &&
      `
      content: '(' counter(item, lower-roman) ')';
    `}
  }
`

export const mainHeadingStyles = ({ theme }) => css`
  font-size: ${theme.fontSizes[8]};
  line-height: ${theme.lineHeights.heading};

  ${mediaQueries.lg} {
    font-size: ${theme.fontSizes[17]};
  }
`

export { H1, H2, H3, H4, H5, H6 }
